//import axios from "axios";
import http from "../http_common";

//const API_URL = "http://localhost:6868/api/v1/auth";
//const API_URL = "http://capoccina.netgroup.uniroma2.it:6868/api/v1/auth";

class AuthService {
  login(user) {
    return http
      .post("/auth/signin", {
        username: user.username,
        password: user.password,
      })
      .then((response) => {
        const data = response.data.data;
        //console.log('LOGIN RESPONSE', data);
        if (data.accessToken) {
          localStorage.setItem("user", JSON.stringify(data));
        }
        return data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  // register(user) {
  //   return http.post("/auth/signup", {
  //     username: user.username,
  //     password: user.password,
  //     first_name: user.firstname,
  //     last_name: user.lastname,
  //     organisation: user.organisation,
  //   });
  // }

  // isClient() {
  //   const user = localStorage.getItem("user");
  //   if (user && user["role"]) {
  //     return ["client"].includes(user["role"]);
  //   }
  //   return false;
  // }

  // isOperator() {
  //   const user = localStorage.getItem("user");
  //   if (user && user["role"]) {
  //     return ["operator"].includes(user["role"]);
  //   }
  //   return false;
  // }

  // isSupervisor() {
  //   const user = localStorage.getItem("user");
  //   if (user && user["role"]) {
  //     return ["supervisor"].includes(user["role"]);
  //   }
  //   return false;
  // }

  // isAdmin() {
  //   const user = localStorage.getItem("user");
  //   console.log('USER', user);
  //   if (user && user["role"]) {
  //     return ["admin"].includes(user["role"]);
  //   }
  //   return false;
  // }

  // isAtLeastSupervisor() {
  //   const user = JSON.parse(localStorage.getItem("user"));
  //   if (user && user["role"]) {
  //     return ["supervisor", "admin"].includes(user["role"]);
  //   }
  //   return false;
  // }

  // isAtLeastOperator() {
  //   const user = localStorage.getItem("user");
  //   if (user && user["role"]) {
  //     return ["operator", "supervisor", "admin"].includes(user["role"]);
  //   }
  //   return false;
  // }
}

export default new AuthService();
