import { createApp } from "vue";
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "primevue/resources/themes/md-light-indigo/theme.css"
import 'primeicons/primeicons.css';
import ToastService from 'primevue/toastservice';
import { FontAwesomeIcon } from "./plugins/font-awesome";

createApp(App)
  .use(router)
  .use(store)
  .use(PrimeVue)
  .use(ConfirmationService)
  .use(ToastService)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");