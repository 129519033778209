<template>
  <div id="app">
    <nav class="navbar navbar-expand navbar-dark bg-primary">
      <a class="navbar-brand" style="margin-left: 1em;">CREDIT2855AAA</a>

      <div v-if="!currentUser" class="navbar-nav ml-auto">
        <!--li class="nav-item">
          <router-link to="/register" class="nav-link">
            <font-awesome-icon icon="user-plus" /> Sign Up
          </router-link>
        </li-->
        <li class="nav-item">
          <router-link to="/login" class="nav-link">
            <font-awesome-icon icon="sign-in-alt" /> Login
          </router-link>
        </li>
      </div>

      <div v-else class="navbar-nav ml-auto">
        <li class="nav-item">
          <router-link v-if="currentUser" to="/counts" class="nav-link">
            Conteggi
          </router-link>
        </li>
        <li v-if="showRates" class="nav-item">
          <router-link v-if="currentUser" to="/rates" class="nav-link"
            >Tassi</router-link
          >
        </li>
        <li v-if="showUsers" class="nav-item">
          <router-link v-if="currentUser" to="/users" class="nav-link"
            >Utenti</router-link
          >
        </li>
        <!--li class="nav-item">
          <router-link to="/profile" class="nav-link">
            <font-awesome-icon icon="profile" /> Profilo
          </router-link>
        </li-->
        <li class="nav-item">
          <router-link to="/profile" class="nav-link">
            <font-awesome-icon icon="user" />
            {{ currentUser.name }}
          </router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click.prevent="logOut">
            <font-awesome-icon icon="sign-out-alt" /> Logout
          </a>
        </li>
      </div>
    </nav>

    <div class="container" style="min-width: 100vw">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    currentUser() {
      let userObj = this.$store.state.auth.user;
      //console.log("User is: ");
      //console.log(userObj);
      if (!userObj) this.logOut();
      return userObj;
    },
    showRates() {
      if (this.currentUser && this.currentUser["role"]) {
        return ["operator", "supervisor", "admin"].includes(
          this.currentUser["role"]
        );
      }
      return false;
    },
    showUsers() {
      if (this.currentUser && this.currentUser["role"]) {
        return ["admin"].includes(this.currentUser["role"]);
      }
      return false;
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
};
</script>
