<template>
  <div class="col-md-12">
    <div class="card card-container">
      <!--
      <img
        id="profile-img"
        src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
        class="profile-img-card"
      />
      -->
      <Form @submit="handleRegister" :validation-schema="schema">
        <div v-if="!successful">
          <div class="form-group">
            <label for="username">Username</label>
            <Field name="username" type="email" class="form-control" />
            <ErrorMessage name="username" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <Field name="password" type="password" class="form-control" />
            <ErrorMessage name="password" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="firstname">Nome</label>
            <Field name="firstname" type="text" class="form-control" />
            <ErrorMessage name="firstname" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="lastname">Cognome</label>
            <Field name="lastname" type="text" class="form-control" />
            <ErrorMessage name="lastname" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="organisation">Organizzazione</label>
            <Field name="organisation" type="text" class="form-control" />
            <ErrorMessage name="organisation" class="error-feedback" />
          </div>

          <div class="form-group">
            <button class="btn btn-primary btn-block" :disabled="loading">
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              Registrati
            </button>
          </div>
        </div>
      </Form>

      <div
        v-if="message"
        class="alert"
        :class="successful ? 'alert-success' : 'alert-danger'"
      >
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "BoardRegister",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      username: yup
        .string()
        .required("Lo username è obbligatorio e deve essere un indirizzo email!")
        .email("Email non valida!")
        .max(50, "Al massimo 50 caratteri!"),
      password: yup
        .string()
        .required("La password è obbligatoria!")
        .min(3, "Almeno 6 caratteri!")
        .max(20, "Al massimo 40 caratteri!"),
      firstname: yup
        .string()
        .required("Il nome è obbligatorio!")
        .min(3, "Almeno 3 caratteri!")
        .max(20, "Al massimo 20 caratteri!"),
      lastname: yup
        .string()
        .required("Il cognome è obbligatorio!")
        .min(3, "Almeno 3 caratteri!")
        .max(20, "Al massimo 20 caratteri!"),
      organisation: yup
        .string()
        .required("L'azienda/organizzazione è obbligatoria!")
        .min(3, "Almeno 3 caratteri!")
        .max(100, "Al massimo 40 caratteri!"),
    });

    return {
      successful: false,
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
  },
  methods: {
    handleRegister(user) {
      this.message = "";
      this.successful = false;
      this.loading = true;

      this.$store.dispatch("auth/register", user).then(
        (data) => {
          this.message = data.message;
          this.successful = true;
          this.loading = false;
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.data &&
              error.response.data.data.message) ||
            error.message ||
            error.toString();
          this.successful = false;
          this.loading = false;
        }
      );
    },
  },
};
</script>
