import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/BoardHome.vue";
import Login from "./components/BoardLogin.vue";
import Register from "./components/BoardRegister.vue";
// lazy-loaded
const Profile = () => import("./components/BoardProfile.vue");

const CountsList = () => import("./components/CountsList.vue");
const CountEdit = () => import("./components/Count.vue");
const CountAdd = () => import("./components/CountAdd.vue");

const RatesList = () => import("./components/RatesList.vue");
const RateEdit = () => import("./components/Rate.vue");
const RateAdd = () => import("./components/RateAdd.vue");

const UsersList = () => import("./components/UsersList.vue");
const UserEdit = () => import("./components/User.vue");
const UserAdd = () => import("./components/UserAdd.vue");


//const BoardAdmin = () =>
//  import("./components/BoardAdmin.vue");
//const BoardModerator = () => import("./components/BoardModerator.vue");
//const BoardUser = () => import("./components/BoardUser.vue");

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/profile",
    name: "profile",
    // lazy-loaded
    component: Profile,
  },
  // CONTEGGI
  {
    path: "/counts",
    name: "list-counts",
    component: CountsList,
  },
  {
    path: "/counts/:id",
    name: "edit-count",
    component: CountEdit,
  },
  {
    path: "/count-add",
    name: "add-count",
    component: CountAdd,
  },
  // TASSI
  {
    path: "/rates",
    name: "list-rates",
    component: RatesList,
  },
  {
    path: "/rates/:id",
    name: "edit-rate",
    component: RateEdit,
  },
  {
    path: "/rate-add",
    name: "add-rate",
    component: RateAdd,
  },
  // UTENTI
  {
    path: "/users",
    name: "users",
    component: UsersList,
  },
  {
    path: "/users/:id",
    name: "edit-user",
    component: UserEdit,
  },
  {
    path: "/user-add",
    name: "add-user",
    component: UserAdd,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register', '/home'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router;
