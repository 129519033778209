import axios from "axios";


const backend_url = process.env.VUE_APP_NODE_URL || "http://localhost:8080";

export default axios.create({
  baseURL: backend_url + "/api/v1",
  //headers: {
  //  "Content-type": "application/json",
  //},
});
